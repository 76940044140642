<template>
  <div class="container-fluid text-left">
    <div class="row">
      <div class="col-sm-12 col-md-12 pt-3">
        <div class="row">
          <div
            style="position: absolute; top: 0; right: 0; background-color: #ffffff; height: 100%; z-index: 2;"
          >
            <transition name="bounce">
              <div v-show="eventId != null" style="width: 350px;">
                <div style="float: right; cursor: pointer;" @click="eventId = null, eventSelected = null">
                  <i
                    class="fas fa-times"
                    style="color: red; font-size: medium; margin-top: 8px; margin-right: 5px;"
                  ></i>
                </div>
                <attendanceListDialog
                  ref="attendanceListDialog"
                  :isOutSideMeeting="true"
                  @goMeeting="goMeeting"
                ></attendanceListDialog>
              </div>
            </transition>
          </div>
          <confirm-modal ref="confirmModal" :content="confirmModalContent" @onConfirm="onConfirm()"></confirm-modal>
          <div class="col-sm-5 pr-0">
            <input
              type="text"
              class="search_input form-control"
              v-on:keyup.enter="onSearch()"
              v-model="search.name"
              style="font-size: 15px;"
              :placeholder="$t('search.searchTitle')"
            />
          </div>
          <div class="col-sm-5 pt-1">
            <button
              class="btn btn-button save-btn text-white"
              style="padding: 0 20px; font-size: 16px"
              @click="onSearch"
            >{{$t('search.buttonSearch')}}</button>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="custom-control custom-checkbox pt-3 pb-3">
          <div class="row">
            <div class="col-sm-2">
              <input
                type="checkbox"
                class="custom-control-input"
                id="periodCheck"
                name="example1"
                v-model="searchTime"
                @change="onCheckSearch"
              />
              <label
                class="custom-control-label font-weight-light"
                style="font"
                for="periodCheck"
              >{{$t('search.period')}}</label>
            </div>
            <div class="col-sm-6 pl-0">
              <div class="row">
                <date-picker
                  style="font-size: 18px; text-align: center; width:116px; height:25px"
                  :config="startDateOptions"
                  v-model="search.start_time"
                  :disabled="!searchTime"
                  @input="onChangeStartDate"
                ></date-picker>
                <div style="width: 37px; text-align: center">-</div>
                <date-picker
                  style="font-size: 18px; text-align: center; width:116px; height:25px"
                  :config="endDateOptions"
                  v-model="search.end_time"
                  :disabled="!searchTime"
                ></date-picker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-3 p-0 border-top">
        <div
          class="col-md-12 mt-3 text-center font-weight-light"
          v-if="emptyList"
        >{{$t('search.emptyList')}}</div>
        <div
          v-else
          class="col-12 pt-3 pb-3 border-bottom border-white event-info-data"
          :style="'background: #ECEFF0; font-size: 15px;' + [eventSelected == item.id ? 'background: #bee3f8;' : '']"
          v-for="(item, index) in listEvent"
          :key="item+index"
          @click="onClickSelectEvent($event, item)"
        >
          <div class="meeting-font-large" style="font-weight: bold;">{{item.name}}</div>
          <div class="meeting-font-medium" v-if="item.start_datetime != null && item.start_datetime != ''">
            <i class="far fa-calendar-check" style="color: orange; margin-right: 15px;"></i>
            <!-- <span>{{item.start_datetime}}</span> -->
            <span>{{formatEventDateTime(item.start_datetime, item.end_datetime)}}</span>
          </div>
          <div class="meeting-font-medium" v-if="item.event_venue_name != null && item.event_venue_name != ''">
            <i class="fas fa-map-marker-alt" style="color: #007bff; margin-right: 15px;"></i>
            <span>{{item.event_venue_name}}</span>
          </div>
          <div class="meeting-font-medium" v-if="item.organization_data != null && item.organization_data != ''">
            <i class="fas fa-user-tie" style="color: pink; margin-right: 15px;"></i>
            <span>{{item.organization_data}}</span>
          </div>
        </div>
      </div>
      <div class="bottom mt-3 ml-3">
        <pagination
          v-if="listEvent.length > 0"
          :rows="EventPaginate.total"
          :per-page="EventPaginate.perPage"
          :current-page="EventPaginate.currentPage"
          :total="EventPaginate.totalEvent"
          @onPageChange="onEventPageChange"
        ></pagination>
      </div>
    </div>
    <snack-bar v-for="snackbar in GET_SNACK_BAR" :key="snackbar.id" :item="snackbar"></snack-bar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import dateUtils from "utils/dateUtils";
import AttendanceListDialog from "Components/AttendanceListDialog";
import ConfirmModal from "Components/ConfirmModal";
import functionUtils from "utils/functionUtils"
import historyActionEnum from 'enum/historyLogEnum'
export default {
  components: {
    confirmModal: ConfirmModal,
    attendanceListDialog: AttendanceListDialog,
  },
  data: () => ({
    confirmModalContent: "meeting.confirmChooseMeeting",
    startDateOptions: {
      format: "DD/MM/YYYY",
      useCurrent: false,
      locale: "vi",
      minDate: false,
      maxDate: false,
    },
    endDateOptions: {
      format: "DD/MM/YYYY",
      useCurrent: false,
      locale: "vi",
      minDate: false,
      maxDate: false,
    },
    listEvent: [],
    search: {
      name: null,
      start_time: "",
      end_time: "",
    },
    searchTitle: false,
    searchTime: false,
    emptyList: false,
    eventId: null,
    eventListSelected: [],
    page_per: 7,
    EventPaginate: {
      total: 1,
      perPage: 1,
      currentPage: 1,
      totalEvent: 0,
    },
    eventTitle: "",
    eventSelected: null
  }),
  watch: {
    GET_EVENT_USER_REGISTERED_DATA: function () {
      // Paginate
      this.EventPaginate.total = this.GET_EVENT_USER_REGISTERED_DATA.paginate.totalPage;
      this.EventPaginate.perPage = 1;
      this.EventPaginate.currentPage = this.GET_EVENT_USER_REGISTERED_DATA.paginate.currentPage;
      this.EventPaginate.totalEvent = this.GET_EVENT_USER_REGISTERED_DATA.paginate.total;

      this.listEvent = [];
      this.emptyList = false;
      this.GET_EVENT_USER_REGISTERED_DATA.results.forEach((data) => {
        let start_datetime = moment(data.start_datetime).format('YYYY-MM-DD HH:mm');
        let end_datetime = moment(data.end_datetime).format('YYYY-MM-DD HH:mm');
        let item = {
          id: data.id,
          name: data.name,
          organization_data: data.organization_data,
          start_datetime: start_datetime,
          end_datetime: end_datetime,
          event_venue_name: data.event_venue_name,
          state_id: data.event_state.id,
        };
        this.listEvent.push(item);
      });
      if ((this.listEvent == "") | (this.listEvent == null)) {
        this.emptyList = true;
      }
    },
  },
  computed: {
    ...mapGetters(["GET_EVENT_USER_REGISTERED_DATA", "GET_SNACK_BAR"]),
  },
  methods: {
    // 
    formatEventDateTime: function(startTime, endTime){
      return functionUtils.formatEventDateTime(startTime, endTime)
    },
    /**
     * On confirm
     */
    onConfirm: function () {
      this.$store.state.app.inAMeeting = true;
      this.$router.push({
        name: "information.title",
      });
      sessionStorage.setItem("event_id", this.eventId);
      let data = {
        action_type: historyActionEnum.JOIN_MEETING,
        description: this.$t('historyLog.descriptionText.joinMeeting'),
        event_id: this.eventId
      }
      this.createHistoryLog(data)
    },
    // Go meeting
    goMeeting: function () {
      this.$refs.confirmModal.onShowModalConfirm(this.eventTitle);
    },
    onEventPageChange: function (page) {
      this.EventPaginate.currentPage = page;
      const customer_data = {
        params: {
          page: this.EventPaginate.currentPage,
          event_name: this.search.name,
          start_time: this.search.start_time,
          end_time: this.search.end_time,
          page_per: this.page_per
        },
      };
      this.GET_EVENT_USER_REGISTERED(customer_data);
    },
    onChangeStartDate: function () {
      this.endDateOptions.minDate = this.search.start_time;
      this.search.end_time = null;
    },
    compareDate: function (start, end) {
      if (start.format("DD-MM-YYYY") === end.format("DD-MM-YYYY")) {
        return start.format("DD/MM/YYYY");
      } else return start.format("DD/MM/YYYY") + " - " + end.format("DD/MM/YYYY");
      // if (start.format("MM-YYYY") === end.format("MM-YYYY")) {
      //   return start.format("DD") + " - " + end.format("DD/MM/YYYY");
      // } else if (start.format("YYYY") === end.format("YYYY")) {
      //   return start.format("DD/MM") + " - " + end.format("DD/MM/YYYY");
      // } else return 0;
    },
    onSearch: function () {
      let start_time_data = null;
      let end_time_data = null;
      if (this.searchTime) {
        start_time_data =
          this.search.start_time != null && this.search.start_time != ""
            ? this.search.start_time
            : null;
        end_time_data =
          this.search.end_time != null && this.search.end_time != ""
            ? this.search.end_time
            : null;

        let check_start_date = dateUtils.formatDate(
          start_time_data,
          dateUtils.STATIC_FORMAT_DATE,
          dateUtils.STATIC_FORMAT_DATE_SQL
        );
        let check_end_date = dateUtils.formatDate(
          end_time_data,
          dateUtils.STATIC_FORMAT_DATE,
          dateUtils.STATIC_FORMAT_DATE_SQL
        );
        if (dateUtils.compareDate(check_end_date, check_start_date) == -1) {
          this.ON_SHOW_TOAST({
            message: this.$t("compareDate.startNotBiggerEndTime"),
            styleType: "danger",
          });
        } else {
          let filter = {
            params: {
              event_name: this.search.name,
              start_time: start_time_data,
              end_time: end_time_data,
              page: 1,
              page_per: this.page_per
            },
          };
          this.GET_EVENT_USER_REGISTERED(filter);
          this.eventId = null;
          this.eventSelected = null;
        }
      } else {
        let filter = {
          params: {
            event_name: this.search.name,
            start_time: start_time_data,
            end_time: end_time_data,
            page: 1,
            page_per: this.page_per
          },
        };
        this.GET_EVENT_USER_REGISTERED(filter);
        this.eventId = null;
        this.eventSelected = null;
      }
    },
    onCheckSearch() {
      if (this.searchTime == false) {
        this.search.start_time = "";
        this.search.end_time = "";
      }
    },
    onClickSelectEvent(event, data) {
      event.preventDefault();
      this.eventTitle = data.name;
      this.eventId = data.id;
      this.eventSelected = data.id;
      this.$refs.attendanceListDialog.getAttendeeSpeakerList(this.eventId, data.state_id);
    },
    createHistoryLog: function (data) {
      this.CREATE_HISTORY_LOG(data).then(
        function () {
        }.bind(this)
      )
    },
    ...mapActions(["GET_EVENT_USER_REGISTERED", "ON_SHOW_TOAST", "CREATE_HISTORY_LOG"]),
  },
  mounted() {
    let filter = {
      params: {
        page: 1,
        page_per: this.page_per
      },
    };
    this.GET_EVENT_USER_REGISTERED(filter);
  },
};
</script>
<style scoped lang="scss">
@import "../../style/color.scss";
.save-btn {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.event-info-data:hover {
  background: #bee3f8 !important;
}
</style>